import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import {
    isRouteErrorResponse,
    json,
    Links,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useLoaderData,
    useNavigation,
    useRouteError,
} from '@remix-run/react'
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node'
import { NextUIProvider } from '@nextui-org/react'

import './tailwind.css'
import { getToast } from 'remix-toast'
import { Toaster, toast as notify } from 'sonner'
import { useEffect } from 'react'
import { ErrorBoundaryPreloader, RouteErrorPage } from './features/error-pages'

export const links: LinksFunction = () => [
    { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    {
        rel: 'preconnect',
        href: 'https://fonts.gstatic.com',
        crossOrigin: 'anonymous',
    },
    {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
    },
]

export const loader = async ({ request }: LoaderFunctionArgs) => {
    // Extracts the toast from the request
    const { toast, headers } = await getToast(request)
    // Important to pass in the headers so the toast is cleared properly
    return json({ toast }, { headers })
}

export function Layout({ children }: { children: React.ReactNode }) {
    return (
        <html lang="en">
            <head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <Meta />
                <Links />
            </head>
            <body>
                <Toaster visibleToasts={10} />

                <NextUIProvider>
                    {children}
                    <ScrollRestoration />
                    <Scripts />
                </NextUIProvider>
            </body>
        </html>
    )
}

function App() {
    const { toast } = useLoaderData<typeof loader>()

    useEffect(() => {
        if (toast) {
            if (toast?.type === 'error') {
                notify.error(toast.message)
            }
            if (toast?.type === 'success') {
                notify.success(toast.message)
            }
        }
    }, [toast])

    return <Outlet />
}

export default withSentry(App)

export function ErrorBoundary() {
    const navigation = useNavigation()
    const error = useRouteError()

    console.error(error)
    captureRemixErrorBoundaryError(error)

    let errorInfo = null

    if (isRouteErrorResponse(error)) {
        errorInfo = (
            <RouteErrorPage
                status={error.status}
                statusText={error.statusText}
                data={error.data}
            />
        )
    } else if (error instanceof Error) {
        errorInfo = (
            <div>
                <h1>Error</h1>
                <p>{error.message}</p>
                <p>The stack trace is:</p>
                <pre>{error.stack}</pre>
            </div>
        )
    } else {
        errorInfo = <h1>Unknown Error</h1>
    }

    return (
        <div className="flex w-screen h-screen justify-center items-center">
            {navigation.state !== 'idle' ? (
                <ErrorBoundaryPreloader />
            ) : (
                errorInfo
            )}
        </div>
    )
}
