import { ErrorResponse, Link, useNavigate } from '@remix-run/react'
import { Button } from '~/components/ui/button'

export const RouteErrorPage = (errorData: {
    status: number
    statusText: string
    data: ErrorResponse['data']
}) => {
    const navigate = useNavigate()

    return (
        <div className="container flex flex-col items-center ">
            <div className="flex flex-col gap-6 max-w-md text-center">
                <h2 className="font-extrabold text-9xl text-gray-600 dark:text-gray-100">
                    {errorData.status}{' '}
                    <span className="sr-only">{errorData.statusText}</span>
                </h2>
                <p className="text-2xl md:text-3xl dark:text-gray-300">
                    {errorData.data}
                </p>
                <div className="grid grid-cols-2 gap-3">
                    <Button
                        onClick={() => navigate(-1)}
                        className="px-8 py-4 text-xl font-semibold rounded bg-cyan-600 text-gray-50 hover:text-gray-200 hover:bg-cyan-800"
                    >
                        Назад
                    </Button>
                    <Button className="px-8 py-4 text-xl font-semibold rounded bg-cyan-600 text-gray-50 hover:text-gray-200 hover:bg-cyan-800">
                        <Link to="/">На главную</Link>
                    </Button>
                </div>
            </div>
        </div>
    )
}
